import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { ObjectPool } from '../object-pool';
import { User } from '../user/model';

export class Document extends ApplicationRecord {
  static prefix = 'documents' as const;

  uploaderId: string;
  path: string;
  mimeType?: string | null;
  sizeBytes?: number | null;
  modifiedAt?: string | null;
  updatedAt: string;
  createdAt: string;
  deletedAt?: string | null;
  fileUrl?: string | null;
  thumbnailUrl?: string | null;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.uploaderId = this.attribute('uploaderId', z.string());
    this.path = this.attribute('path', z.string());
    this.mimeType = this.attribute('mimeType', z.string().nullish());
    this.sizeBytes = this.attribute('sizeBytes', z.number().nullish());
    this.modifiedAt = this.attribute(
      'modifiedAt',
      z.string().datetime({ offset: true }).nullish()
    );
    this.updatedAt = this.attribute(
      'updatedAt',
      z.string().datetime({ offset: true })
    );
    this.createdAt = this.attribute(
      'createdAt',
      z.string().datetime({ offset: true })
    );
    this.deletedAt = this.attribute(
      'deletedAt',
      z.string().datetime({ offset: true }).nullish()
    );
    this.fileUrl = this.attribute('fileUrl', z.string().nullish());
    this.thumbnailUrl = this.attribute('thumbnailUrl', z.string().nullish());
  }

  get uploader() {
    return this.belongsTo(User, 'uploaderId');
  }
}
