import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';
import { SubmittalDocument } from './submittal-document';

export class SubmittalDocumentPage extends ApplicationRecord {
  id: string;
  createdAt: string;
  updatedAt: string;
  pdfPageId: string;
  submittalDocumentId: string;

  static prefix = 'submittalDocumentPages' as const;

  static schema = z.object({
    id: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    pdfPageId: z.string(),
    submittalDocumentId: z.string(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.pdfPageId = this.attribute('pdfPageId');
    this.submittalDocumentId = this.attribute('submittalDocumentId');
  }

  get submittalDocument() {
    return this.belongsTo(SubmittalDocument, this.submittalDocumentId);
  }
}
