import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { Discussion } from '../discussion/model';
import { ObjectPool } from '../object-pool';
import { User } from '../user/model';

export class CommentThread extends ApplicationRecord {
  static prefix = 'commentThreads' as const;

  discussionId: string;
  resolvedAt?: string | null;
  resolvedById?: string | null;
  createdAt: string;
  updatedAt: string;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.discussionId = this.attribute('discussionId', z.string());
    this.resolvedAt = this.attribute(
      'resolvedAt',
      z.string().datetime({ offset: true }).nullish()
    );
    this.resolvedById = this.attribute('resolvedById', z.string().nullish());
    this.createdAt = this.attribute(
      'createdAt',
      z.string().datetime({ offset: true })
    );
    this.updatedAt = this.attribute(
      'updatedAt',
      z.string().datetime({ offset: true })
    );
  }

  get resolvedBy() {
    return this.belongsToOptional(User, this.resolvedById);
  }

  get discussion() {
    return this.belongsTo(Discussion, this.discussionId);
  }
}
