import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { Document } from '../document/model';
import { ObjectPool } from '../object-pool';

export class DocumentPage extends ApplicationRecord {
  static prefix = 'documentPages' as const;

  projectId: string;
  organizationId: string;
  documentId: string;
  pageNumber: number;
  thumbnailUrl?: string | null;
  fileUrl?: string | null;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.projectId = this.attribute('projectId', z.string());
    this.organizationId = this.attribute('organizationId', z.string());
    this.documentId = this.attribute('documentId', z.string());
    this.pageNumber = this.attribute('pageNumber', z.number());
    this.thumbnailUrl = this.attribute('thumbnailUrl', z.string().nullish());
    this.fileUrl = this.attribute('fileUrl', z.string().nullish());
  }

  get document() {
    return this.belongsTo(Document, this.documentId);
  }
}
