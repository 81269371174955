import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { Topic } from '../issue/model';
import { ObjectPool, RegisteredModelPrefix } from '../object-pool';
import { PendingAttachment } from '../pending-attachment/model';
import { User } from '../user/model';
import { RfiBallInCourtStatus, RfiStatus } from './schema';

export class Rfi extends ApplicationRecord {
  ballInCourtStatus: RfiBallInCourtStatus;
  dueOn?: string | null;
  openedAt: string;
  closedAt?: string | null;
  question: string;
  status: RfiStatus;
  subject: string;
  number: number;
  topicId?: string | null;
  createdAt: string;
  updatedAt: string;
  managerUserId: string;

  static prefix = 'rfis' as const;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.ballInCourtStatus = this.attribute(
      'ballInCourtStatus',
      z.enum(['manager', 'respondent'])
    );
    this.dueOn = this.attribute('dueOn', z.string().date().nullish());
    this.openedAt = this.attribute('openedAt', z.string().datetime());
    this.closedAt = this.attribute('closedAt', z.string().datetime().nullish());
    this.question = this.attribute('question', z.string());
    this.status = this.attribute(
      'status',
      z.enum(['open', 'closed', 'cancelled'])
    );
    this.subject = this.attribute('subject', z.string());
    this.number = this.attribute('number', z.number().nonnegative());
    this.topicId = this.attribute('topicId', z.string().nullish());
    this.createdAt = this.attribute('createdAt', z.string().datetime());
    this.updatedAt = this.attribute('updatedAt', z.string().datetime());
    this.managerUserId = this.attribute('managerUserId', z.string());
  }

  get managerUser() {
    return this.belongsTo(User, this.managerUserId);
  }

  get topic() {
    return this.belongsToOptional(Topic, this.topicId);
  }

  get subscribers() {
    return this.hasMany(RfiSubscriber, 'rfiId');
  }

  get respondents() {
    return this.subscribers.filter((subscriber) => subscriber.responseRequired);
  }

  get pendingRespondents() {
    return this.respondents.filter(
      (respondent) =>
        !this.responses.some(
          (response) => response.subscriber.userId === respondent.userId
        )
    );
  }

  get responses() {
    return this.hasMany(RfiResponse, 'rfiId');
  }

  get questionAttachments() {
    return this.hasMany(RfiQuestionAttachment, 'rfiId');
  }
}

export class RfiResponse extends ApplicationRecord {
  content: string;
  rfiId: string;
  subscriberId: string;
  acceptedAt?: string | null;
  createdAt: string;
  updatedAt: string;

  static prefix = 'rfiResponses' as const;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.content = this.attribute('content', z.string());
    this.rfiId = this.attribute('rfiId', z.string());
    this.subscriberId = this.attribute('subscriberId', z.string());
    this.acceptedAt = this.attribute(
      'acceptedAt',
      z.string().datetime().nullish()
    );
    this.createdAt = this.attribute('createdAt', z.string().datetime());
    this.updatedAt = this.attribute('updatedAt', z.string().datetime());
  }

  get rfi() {
    return this.belongsTo(Rfi, this.rfiId);
  }

  get subscriber() {
    return this.belongsTo(RfiSubscriber, this.subscriberId);
  }

  get user() {
    return this.subscriber.user;
  }

  get responseAttachments() {
    return this.hasMany(RfiResponseAttachment, 'responseId');
  }
}

export class RfiSubscriber extends ApplicationRecord {
  responseRequired: boolean;
  rfiId: string;
  userId: string;
  createdAt: string;
  updatedAt: string;

  static prefix = 'rfiSubscribers' as const;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.responseRequired = this.attribute('responseRequired', z.boolean());
    this.rfiId = this.attribute('rfiId', z.string());
    this.userId = this.attribute('userId', z.string());
    this.createdAt = this.attribute('createdAt', z.string().datetime());
    this.updatedAt = this.attribute('updatedAt', z.string().datetime());
  }

  get rfi() {
    return this.belongsTo(Rfi, this.rfiId);
  }

  get user() {
    return this.belongsTo(User, this.userId);
  }
}

export class RfiQuestionAttachment extends ApplicationRecord {
  rfiId: string;
  fileName?: string | null;
  fileUrl?: string | null;
  thumbnailUrl?: string | null;
  createdAt: string;

  static prefix = 'rfiQuestionAttachments' as const;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.rfiId = this.attribute('rfiId', z.string());
    this.fileName = this.attribute('fileName', z.string().nullish());
    this.fileUrl = this.attribute('fileUrl', z.string().nullish());
    this.thumbnailUrl = this.attribute('thumbnailUrl', z.string().nullish());
    this.createdAt = this.attribute('createdAt', z.string().datetime());
  }

  get rfi() {
    return this.belongsToOptional(Rfi, this.rfiId);
  }

  get pendingAttachment() {
    return this.hasOne(PendingAttachment, 'recordId');
  }
}

export class RfiResponseAttachment extends ApplicationRecord {
  responseId: string;
  fileName?: string | null;
  fileUrl?: string | null;
  thumbnailUrl?: string | null;
  createdAt: string;

  static prefix = 'rfiResponseAttachments' as const;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.responseId = this.attribute('responseId', z.string());
    this.fileName = this.attribute('fileName', z.string().nullish());
    this.fileUrl = this.attribute('fileUrl', z.string().nullish());
    this.thumbnailUrl = this.attribute('thumbnailUrl', z.string().nullish());
    this.createdAt = this.attribute('createdAt', z.string().datetime());
  }

  get pendingAttachment() {
    return this.hasOne(PendingAttachment, 'recordId');
  }
}
