import { makeAutoObservable } from 'mobx';
import { ReadonlyJSONObject } from 'replicache';

import invariant from '@/lib/invariant';
import { createContext, useContext } from 'react';
import { ActivityLog } from './activity-log/model';
import { CommentThread } from './comment-thread/model';
import { Comment } from './comment/model';
import { Company } from './company/model';
import { CompanyMembership } from './company_membership/model';
import {
  DailyLog,
  DailyLogEquipmentEntry,
  DailyLogPersonnelEntry,
} from './daily-log/model';
import { Discussion } from './discussion/model';
import { DocumentPage } from './document-page/model';
import { Document } from './document/model';
import { DrawingArea } from './drawing-area/model';
import { DrawingSetPage } from './drawing-set-page/model';
import { DrawingSet } from './drawing-set/model';
import { Topic } from './issue/model';
import { MarkupShape } from './markup-shape/model';
import { Notification } from './notification/model';
import { OrganizationMembership } from './organization-membership/model';
import { Organization } from './organization/model';
import { PendingAttachment } from './pending-attachment/model';
import { Photo } from './photo/model';
import { Project } from './project';
import { ProjectMembership } from './project_membership/model';
import { PunchListItem } from './punch-list-item';
import { Question } from './question/model';
import {
  Rfi,
  RfiQuestionAttachment,
  RfiResponse,
  RfiResponseAttachment,
  RfiSubscriber,
} from './rfi/model';
import { SheetNumberLink } from './sheet-number-link/model';
import { Submittal } from './submittal';
import { SubmittalApprover } from './submittal-approver';
import { SubmittalApproverDocument } from './submittal-approver-document';
import { SubmittalDocument } from './submittal-document';
import { SubmittalDocumentPage } from './submittal-document-page';
import { SubmittalGroup } from './submittal-group';
import { SubmittalItem } from './submittal-item';
import { SubmittalStage } from './submittal-stage';
import { SubmittalSubscriber } from './submittal-subscriber';
import { User } from './user/model';

export type ModelRegistry = typeof modelRegistry;

export type RegisteredModelPrefix = keyof ModelRegistry;
export type RegisteredModelPrototype =
  (typeof modelRegistry)[keyof ModelRegistry];
export type RegisteredModelInstance = InstanceType<RegisteredModelPrototype>;

const modelRegistry = {
  activityLogs: ActivityLog,
  comments: Comment,
  commentThreads: CommentThread,
  dailyLogs: DailyLog,
  dailyLogPersonnelEntries: DailyLogPersonnelEntry,
  dailyLogEquipmentEntries: DailyLogEquipmentEntry,
  discussions: Discussion,
  documents: Document,
  questions: Question,
  documentPages: DocumentPage,
  drawingAreas: DrawingArea,
  drawingSets: DrawingSet,
  drawingSetPages: DrawingSetPage,
  markupShapes: MarkupShape,
  punchListItems: PunchListItem,
  projects: Project,
  users: User,
  organizationMemberships: OrganizationMembership,
  organizations: Organization,
  sheetNumberLinks: SheetNumberLink,
  photos: Photo,
  pendingAttachments: PendingAttachment,
  notifications: Notification,
  rfis: Rfi,
  rfiResponses: RfiResponse,
  rfiSubscribers: RfiSubscriber,
  rfiQuestionAttachments: RfiQuestionAttachment,
  rfiResponseAttachments: RfiResponseAttachment,
  issues: Topic,
  companies: Company,
  companyMemberships: CompanyMembership,
  projectMemberships: ProjectMembership,
  submittals: Submittal,
  submittalApprovers: SubmittalApprover,
  submittalDocumentPages: SubmittalDocumentPage,
  submittalDocuments: SubmittalDocument,
  submittalApproverDocuments: SubmittalApproverDocument,
  submittalGroups: SubmittalGroup,
  submittalItems: SubmittalItem,
  submittalStages: SubmittalStage,
  submittalSubscribers: SubmittalSubscriber,
};

export function getRegisteredModelPrefix(
  model: RegisteredModelPrototype
): RegisteredModelPrefix {
  for (const [prefix, registeredModel] of Object.entries(modelRegistry)) {
    if (model === registeredModel) {
      return prefix as RegisteredModelPrefix;
    }
  }
  invariant(false, `Unknown model: ${model.name}`);
}

export interface PoolObject {
  id: string;
  __prefix: string;
  objectPool: ObjectPool;
}

export class RecordNotFoundError extends Error {
  constructor(replicacheId: string) {
    super(`Record not found: ${replicacheId}`);
  }
}

export type CacheType = 'user' | 'membership' | 'project';

export class ObjectPool {
  objects: Array<RegisteredModelInstance> = [];
  objectCaches: Record<string, CacheType> = {};

  static initializeSingleton() {
    invariant(!objectPool, 'ObjectPool instance already initialized');
    objectPool = new ObjectPool();
    return objectPool;
  }

  constructor() {
    makeAutoObservable(this);
  }

  addObject(cache: CacheType, key: string, attributes: ReadonlyJSONObject) {
    const [prefix, id] = key.split('/');
    if (!prefix || !id) return;
    if (!(prefix in modelRegistry)) return;

    const ModelClass = modelRegistry[prefix as RegisteredModelPrefix];
    const model = new ModelClass(id, attributes, this);

    this.objects.push(model);
    this.objectCaches[model.id] = cache;

    return model;
  }

  removeObject(key: string) {
    const [prefix, id] = key.split('/');
    if (!prefix || !id) return;
    delete this.objectCaches[id];
    this.objects = this.objects.filter((obj) => obj.id !== id);
  }

  updateObject(key: string, attributes: ReadonlyJSONObject) {
    const [prefix, id] = key.split('/');
    if (!prefix || !id) return;

    const object = this.objects.find(
      (obj) => obj.__prefix === prefix && obj.id === id
    );
    if (object) {
      Object.assign(object, attributes);
    }
  }

  removeCache(cache: CacheType) {
    this.objects = this.objects.filter(
      (obj) => this.objectCaches[obj.id] !== cache
    );
  }

  all<T extends RegisteredModelPrototype>(type: T): Array<InstanceType<T>> {
    const prefix = getRegisteredModelPrefix(type);
    const objects = this.objects.filter(
      (obj) => obj.__prefix === prefix
    ) as Array<InstanceType<T>>;
    objects.forEach((obj) => obj.observeIfNeeded());
    return objects;
  }

  find<T extends RegisteredModelPrototype>(
    type: T,
    id: string
  ): InstanceType<T> | null {
    const prefix = getRegisteredModelPrefix(type);
    const object =
      this.objects.find((obj) => obj.__prefix === prefix && obj.id === id) ||
      null;

    object?.observeIfNeeded();
    return object as InstanceType<T> | null;
  }
}

export let objectPool: ObjectPool;

export const ObjectPoolContext = createContext<ObjectPool | null>(null);

export const useObjectPool = () => {
  const objectPool = useContext(ObjectPoolContext);
  if (!objectPool) {
    throw new Error('useObjectPool must be used within a ObjectPoolProvider');
  }
  return objectPool;
};
