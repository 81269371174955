import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { ObjectPool } from '../object-pool';
import { Project } from '../project';

export class Question extends ApplicationRecord {
  static prefix = 'questions' as const;

  projectId: string;
  content: string;
  completedAt?: string | null;
  answer?: string | null;
  createdAt: string;
  updatedAt: string;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.projectId = this.attribute('projectId', z.string());
    this.content = this.attribute('content', z.string());
    this.completedAt = this.attribute(
      'completedAt',
      z.string().datetime({ offset: true }).nullish()
    );
    this.answer = this.attribute('answer', z.string().nullish());
    this.createdAt = this.attribute(
      'createdAt',
      z.string().datetime({ offset: true })
    );
    this.updatedAt = this.attribute(
      'updatedAt',
      z.string().datetime({ offset: true })
    );
  }

  get project() {
    return this.belongsTo(Project, this.projectId);
  }
}
