import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { ObjectPool, RegisteredModelPrefix } from '../object-pool';

export class Notification extends ApplicationRecord {
  type: string;
  url?: string | null;
  message: string;
  organizationName?: string | null;
  projectName?: string | null;
  topicName?: string | null;
  avatarFirstName?: string | null;
  avatarLastName?: string | null;
  avatarId?: string | null;
  readAt?: string | null;
  seenAt?: string | null;
  createdAt: string;
  updatedAt: string;

  static prefix = 'notifications' as const;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.type = this.attribute('type', z.string());
    this.url = this.attribute('url', z.string().nullish());
    this.message = this.attribute('message', z.string());
    this.organizationName = this.attribute(
      'organizationName',
      z.string().nullish()
    );
    this.projectName = this.attribute('projectName', z.string().nullish());
    this.topicName = this.attribute('topicName', z.string().nullish());
    this.avatarFirstName = this.attribute(
      'avatarFirstName',
      z.string().nullish()
    );
    this.avatarLastName = this.attribute(
      'avatarLastName',
      z.string().nullish()
    );
    this.avatarId = this.attribute('avatarId', z.string().nullish());
    this.readAt = this.attribute('readAt', z.string().nullish());
    this.seenAt = this.attribute('seenAt', z.string().datetime().nullish());
    this.createdAt = this.attribute('createdAt', z.string().datetime());
    this.updatedAt = this.attribute('updatedAt', z.string().datetime());
  }

  static unreadCount(objectPool: ObjectPool) {
    return objectPool.all(Notification).filter((x) => !x.readAt).length;
  }
}
